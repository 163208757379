<template>
	<DefaultLayout>
		<div
			id="wrap404"
			class="p-4"
		>
			<Error404 />
		</div>
	</DefaultLayout>
</template>

<script async>
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		Error404: () => import('@/components/error/404.vue'),
		DefaultLayout
	}
}
</script>
